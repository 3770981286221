import { useEffect, useState } from "react";
import { Col, Dropdown, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { logoSecondary } from "../../urlConfig";

const Header = () => {
  const [data, setData] = useState(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const hospitalLogo = localStorage.getItem("logo");

  useEffect(() => {
    if (localStorage.hasOwnProperty("username")) {
      let username = localStorage.getItem("username");

      setData({
        username: username,
      });
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();

    navigate("/login");
  };

  return (
    <Row className="m-0 header">
      <Col xs={6}>
        <Image
          src={ hospitalLogo ? hospitalLogo : "/img/logo/logo-hospita.svg"}
          height={"50px"}
        />
        <Image
          src={ logoSecondary ? logoSecondary : "/img/logo/logo-hospita.svg"}
          height={"50px"}
          style={{marginLeft:'40px'}}
        />
      </Col>
      <Col xs={6}>
        <Row>
          <Col className="d-flex align-center justify-content-end">
            <Image
              className="header-profile-circle"
              src="/img/icon/icon-profile.svg"
            />

            <div className="d-flex flex-column">
              <span className="fw-bold cl-white">{data?.username}</span>
              <span className="fw-semi-bold cl-green">Admin</span>
            </div>

            <Dropdown>
              <Dropdown.Toggle className="header-dropdown">
                <Image src="/img/icon/icon-arrow.svg" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu-control">
                <Dropdown.Item
                  className="dropdown-item-control"
                  onClick={handleLogout}
                >
                  Keluar
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Header;
