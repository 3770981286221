export const authConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
};

export const queueMachineConstants = {
  GET_QUEUE_MACHINE_REQUEST: "GET_QUEUE_MACHINE_REQUEST",
  GET_QUEUE_MACHINE_FAILURE: "GET_QUEUE_MACHINE_FAILURE",
  GET_QUEUE_MACHINE_SUCCESS: "GET_QUEUE_MACHINE_SUCCESS",
  GET_ALL_QUEUE_MACHINE_REQUEST: "GET_ALL_QUEUE_MACHINE_REQUEST",
  GET_ALL_QUEUE_MACHINE_FAILURE: "GET_ALL_QUEUE_MACHINE_FAILURE",
  GET_ALL_QUEUE_MACHINE_SUCCESS: "GET_ALL_QUEUE_MACHINE_SUCCESS",
  UPDATE_QUEUE_MACHINE_REQUEST: "UPDATE_QUEUE_MACHINE_REQUEST",
  UPDATE_QUEUE_MACHINE_FAILURE: "UPDATE_QUEUE_MACHINE_FAILURE",
  UPDATE_QUEUE_MACHINE_SUCCESS: "UPDATE_QUEUE_MACHINE_SUCCESS",
};

export const counterConstants = {
  GET_COUNTER_REQUEST: "GET_COUNTER_REQUEST",
  GET_COUNTER_FAILURE: "GET_COUNTER_FAILURE",
  GET_COUNTER_SUCCESS: "GET_COUNTER_SUCCESS",
  GET_ALL_COUNTER_REQUEST: "GET_ALL_COUNTER_REQUEST",
  GET_ALL_COUNTER_FAILURE: "GET_ALL_COUNTER_FAILURE",
  GET_ALL_COUNTER_SUCCESS: "GET_ALL_COUNTER_SUCCESS",
  GET_ALL_COUNTER_POLI_REQUEST: "GET_ALL_COUNTER_POLI_REQUEST",
  GET_ALL_COUNTER_POLI_FAILURE: "GET_ALL_COUNTER_POLI_FAILURE",
  GET_ALL_COUNTER_POLI_SUCCESS: "GET_ALL_COUNTER_POLI_SUCCESS",
};

export const queueProcessConstants = {
  GET_QUEUE_PROCESS_REQUEST: "GET_QUEUE_PROCESS_REQUEST",
  GET_QUEUE_PROCESS_FAILURE: "GET_QUEUE_PROCESS_FAILURE",
  GET_QUEUE_PROCESS_SUCCESS: "GET_QUEUE_PROCESS_SUCCESS",
  GET_DASHBOARD_REQUEST: "GET_DASHBOARD_REQUEST",
  GET_DASHBOARD_FAILURE: "GET_DASHBOARD_FAILURE",
  GET_DASHBOARD_SUCCESS: "GET_DASHBOARD_SUCCESS",
  GET_ALL_QUEUE_PROCESS_REQUEST: "GET_ALL_QUEUE_PROCESS_REQUEST",
  GET_ALL_QUEUE_PROCESS_FAILURE: "GET_ALL_QUEUE_PROCESS_FAILURE",
  GET_ALL_QUEUE_PROCESS_SUCCESS: "GET_ALL_QUEUE_PROCESS_SUCCESS",
  NEXT_CALL_QUEUE_PROCESS_REQUEST: "NEXT_CALL_QUEUE_PROCESS_REQUEST",
  NEXT_CALL_QUEUE_PROCESS_FAILURE: "NEXT_CALL_QUEUE_PROCESS_FAILURE",
  NEXT_CALL_QUEUE_PROCESS_SUCCESS: "NEXT_CALL_QUEUE_PROCESS_SUCCESS",
  CREATE_QUEUE_PROCESS_REQUEST: "CREATE_QUEUE_PROCESS_REQUEST",
  CREATE_QUEUE_PROCESS_FAILURE: "CREATE_QUEUE_PROCESS_FAILURE",
  CREATE_QUEUE_PROCESS_SUCCESS: "CREATE_QUEUE_PROCESS_SUCCESS",
  GET_ALL_SKIPPED_QUEUE_PROCESS_REQUEST: "GET_ALL_SKIPPED_QUEUE_PROCESS_REQUEST",
  GET_ALL_SKIPPED_QUEUE_PROCESS_FAILURE: "GET_ALL_SKIPPED_QUEUE_PROCESS_FAILURE",
  GET_ALL_SKIPPED_QUEUE_PROCESS_SUCCESS: "GET_ALL_SKIPPED_QUEUE_PROCESS_SUCCESS",
  UPDATE_SKIPPED_QUEUE_PROCESS_REQUEST: "UPDATE_SKIPPED_QUEUE_PROCESS_REQUEST",
  UPDATE_SKIPPED_QUEUE_PROCESS_FAILURE: "UPDATE_SKIPPED_QUEUE_PROCESS_FAILURE",
  UPDATE_SKIPPED_QUEUE_PROCESS_SUCCESS: "UPDATE_SKIPPED_QUEUE_PROCESS_SUCCESS",
};

export const allQueueMachine={
    PHARMACY_SLUG: 'antrian-farmasi',
    ADMISSION_SLUG: 'antrian-pendaftaran',
    POLICLINIC_SLUG: 'antrian-poliklinik',
    BED_SLUG: 'antrian-kasur'
}

export const maxLimit={
  tvDisplayMissed : 12,
  tvCounter : 5
}

export const policlinicConfigConstants = {
  GET_POLICLINIC_SCREEN_REQUEST: "GET_POLICLINIC_SCREEN_REQUEST",
  GET_POLICLINIC_SCREEN_FAILURE: "GET_POLICLINIC_SCREEN_FAILURE",
  GET_POLICLINIC_SCREEN_SUCCESS: "GET_POLICLINIC_SCREEN_SUCCESS",
  CREATE_POLICLINIC_SCREEN_REQUEST: "CREATE_POLICLINIC_SCREEN_REQUEST",
  CREATE_POLICLINIC_SCREEN_FAILURE: "CREATE_POLICLINIC_SCREEN_FAILURE",
  CREATE_POLICLINIC_SCREEN_SUCCESS: "CREATE_POLICLINIC_SCREEN_SUCCESS",
  UPDATE_POLICLINIC_SCREEN_REQUEST: "UPDATE_POLICLINIC_SCREEN_REQUEST",
  UPDATE_POLICLINIC_SCREEN_FAILURE: "UPDATE_POLICLINIC_SCREEN_FAILURE",
  UPDATE_POLICLINIC_SCREEN_SUCCESS: "UPDATE_POLICLINIC_SCREEN_SUCCESS",
  DELETE_POLICLINIC_SCREEN_REQUEST: "DELETE_POLICLINIC_SCREEN_REQUEST",
  DELETE_POLICLINIC_SCREEN_FAILURE: "DELETE_POLICLINIC_SCREEN_FAILURE",
  DELETE_POLICLINIC_SCREEN_SUCCESS: "DELETE_POLICLINIC_SCREEN_SUCCESS",
  GET_ALL_DOCTOR_POLI_LIST_REQUEST: "GET_ALL_DOCTOR_POLI_LIST_REQUEST",
  GET_ALL_DOCTOR_POLI_LIST_SUCCESS: "GET_ALL_DOCTOR_POLI_LIST_SUCCESS",
  GET_ALL_DOCTOR_POLI_LIST_FAILURE: "GET_ALL_DOCTOR_POLI_LIST_FAILURE",
  GET_ALL_POLI_LIST_REQUEST: "GET_ALL_POLI_LIST_REQUEST",
  GET_ALL_POLI_LIST_SUCCESS: "GET_ALL_POLI_LIST_SUCCESS",
  GET_ALL_POLI_LIST_FAILURE: "GET_ALL_POLI_LIST_FAILURE",
  GET_ALL_DOCTOR_QUEUE_TABLE_SCREEN_REQUEST: "GET_ALL_DOCTOR_QUEUE_TABLE_SCREEN_REQUEST",
  GET_ALL_DOCTOR_QUEUE_TABLE_SCREEN_SUCCESS: "GET_ALL_DOCTOR_QUEUE_TABLE_SCREEN_SUCCESS",
  GET_ALL_DOCTOR_QUEUE_TABLE_SCREEN_FAILURE: "GET_ALL_DOCTOR_QUEUE_TABLE_SCREEN_FAILURE",
}

export const bedConstants = {
  GET_BED_LIST_REQUEST: "GET_BED_LIST_REQUEST",
  GET_BED_LIST_FAILURE: "GET_BED_LIST_FAILURE",
  GET_BED_LIST_SUCCESS: "GET_BED_LIST_SUCCESS",
}

export const doctorConstants = {
  GET_DOCTOR_LIST_REQUEST: "GET_DOCTOR_LIST_REQUEST",
  GET_DOCTOR_LIST_FAILURE: "GET_DOCTOR_LIST_FAILURE",
  GET_DOCTOR_LIST_SUCCESS: "GET_DOCTOR_LIST_SUCCESS",
}

export const ERROR_RESPONSE = {
errorCode: 500,
errorMessage: "Sedang ada kendala, mohon coba beberapa saat lagi"
}