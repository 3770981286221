import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllQueueProcess } from "../../actions";
import "./index.css";
import io from "socket.io-client";
import { api, socketBase, socketPath } from "../../urlConfig";
import { useSpeechSynthesis } from "react-speech-kit";
import { queueProcessConstants } from "../../actions/constants";

const socket = io(socketBase, {path: socketPath}).connect();

const QueueDisplay = () => {
  const { machineId } = useParams();

  // Room State
  const [room] = useState(machineId);
  let { allQueueProcess } = useSelector((state) => state.queueProcess);

  const { speak, voices } = useSpeechSynthesis();
  const idx = voices.findIndex(
    (opt) => opt.lang === "id-ID" && opt.voiceURI === "Google Bahasa Indonesia"
  );
  const voice = voices[idx];

  const dispatch = useDispatch();

  useEffect(() => {
    socket.emit("join_room", room);
  }, [room]);

  useEffect(() => {
    dispatch(getAllQueueProcess(machineId, '','')).catch((err) => console.log(err));
  }, [dispatch, machineId]);

  useEffect(() => {
    socket.on("updateReduxAdmissionDisplay", (newData) => {
      dispatch({
        type: queueProcessConstants.GET_ALL_QUEUE_PROCESS_SUCCESS,
        payload: newData.process,
      });
      speak({
        text: newData.text,
        voice,
        rate: 1,
        pitch: 1,
      })})
  
    socket.on("receive_message", (data) => {
      speak({
        text: data.text,
        voice,
        rate: 1,
        pitch: 1,
      })})
    
  
    return () => {
      socket.off("updateReduxAdmissionDisplay");
      socket.off("receive_message");
  }
  }, [socket]);


  const renderQueueTable = () => {
    return allQueueProcess && allQueueProcess.length > 0 ? (
      allQueueProcess.map((data, index) => (
        <Row
          className={
            (index + 1) % 2 === 0
              ? "queue-table-body-v2 m-0"
              : "queue-table-body m-0"
          }
          key={index}
        >
          <Col>
            <span className="fs-25">{data?.counter}</span>
          </Col>

          <Col>
            <span className="fs-25">{data?.queuePrefixNumber}</span>
          </Col>
        </Row>
      ))
    ) : (
      <Row className="queue-table-body m-0 text-center">
        <Col>
          <span className="fs-25">Belum Ada Antrian</span>
        </Col>
      </Row>
    );
  };

  return (
    <div className="queue-bg">
      <div className="queue-container">
        <Row className="m-0 mb-4">
          <Col>
            <Image src="/img/logo/logo-login-hospita.svg" height={"50px"} />
          </Col>
        </Row>
        <Row className="m-0">
          <Col md={5}>
            <Row>
              <Col className="mb-4">
                <div className="queue-card">
                  <span className="fw-bold fs-60 cl-green">
                  {allQueueProcess && allQueueProcess.length > 0 && allQueueProcess[allQueueProcess.length-1]?.counter}
                  </span>
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="mb-4">
                <div className="queue-card-v2">
                  <div className="queue-card-v2-title">
                    <span className="fw-semi-bold fs-40 cl-white">
                      Nomor Antrian
                    </span>
                  </div>

                  <div className="queue-card-v2-body">
                    <span className="fw-bold fs-60 cl-green">
                      {allQueueProcess && allQueueProcess.length > 0 && allQueueProcess[allQueueProcess.length-1]?.queuePrefixNumber}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col md={7} className="mb-4">
            <div className="queue-card-v3">
              <Row className="m-0 queue-table-header">
                <Col className="overflow-ellipsis">
                  <span className="fw-semi-bold fs-25">Loket</span>
                </Col>

                <Col className="overflow-ellipsis">
                  <span className="fw-semi-bold fs-25">Nomor Antrian</span>
                </Col>
              </Row>

              <div className="queue-table-row-body">{renderQueueTable()}</div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default QueueDisplay;
