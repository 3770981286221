import React, { useEffect, useState } from "react";
import { Col, Dropdown, Image, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HiArrowSmLeft } from "react-icons/hi";
import Button from "../../../components/Button";
import {
  getCounter,
  getQueueMachine,
  getQueueProcess,
  nextCallQueueProcess,
  getAllSkippedCall,
  getAllQueueProcess,
} from "../../../actions";
import Modal from "../../../components/Modal";
import "./index.css";
import io from "socket.io-client";
import { socketBase, socketPath } from "../../../urlConfig";
import Header from "../../../components/Header";
import CopyRightWords from "../../../components/CopyrightWords";

const socket = io(socketBase, {path: socketPath}).connect();

export default function AdminDisplayNoAntrol() {
  const { machineId, counterId } = useParams();
  const [modal, setModal] = useState(false);

  // Room State
  const [room, setRoom] = useState(null);
  // Machine State
  const [machine, setMachine] = useState(null);
  // Counter State
  const [counter, setCounter] = useState(null);
  // Queue State
  const [queue, setQueue] = useState("-");
  const { queueProcessSkipped, allQueueProcess } = useSelector((state) => state.queueProcess);
  
  const queueProcess = useSelector((state) => state.queueProcess);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    setRoom(machineId);
  }, [machineId]);

  useEffect(() => {
    socket.emit("join_room", room);
  }, [room]);

  useEffect(() => {
    dispatch(getQueueMachine(machineId))
    .then((data) => {
      setMachine(data);
    })
    .catch((err) => console.log(err));
  }, [dispatch, machineId]);

  useEffect(() => {
    dispatch(getCounter(counterId))
    .then((data) => {
      setCounter(data);
    })
    .catch((err) => console.log(err));
  }, [dispatch, counterId]);

  useEffect(() => {
    dispatch(getQueueProcess(machineId, counterId))
    .then((data) => {
      if (data) {
        setQueue(data.queuePrefixNumber);
      }
    })
    .catch((err) =>
      console.log(err)
    );
    
  }, [counterId, dispatch, machineId]);

  useEffect(() => {
    if (queueProcess.nextError) {
      setModal(true);
    }
  }, [queueProcess.nextError]);

  const sendMessage = (message, text) => {
    socket.emit("send_message", { message, room, text });
  };

  const renderModal = () => {
    return (
      <Modal
        size="md"
        show={modal}
        handleClose={() => setModal(false)}
        title="Konfirmasi"
      >
        <Row className="m-0 text-center">
          <Col>
            <Image src="/img/icon/icon-timedate.svg" height={"100px"} />
          </Col>
        </Row>

        <Row className="m-0 mb-5 text-center">
          <Col>
            <span>Belum ada antrian</span>
          </Col>
        </Row>

        <Row className="m-0">
          <Col>
            <Button title="Tutup" onClick={() => setModal(false)} />
          </Col>
        </Row>
      </Modal>
    );
  };

  const handleVoiceCall = (queue) => {
    if (queue !== "-" && counter) {
      return `Nomor antrian ${queue}, silakan menuju ${counter.name}`
    }
    return "";
  }

  const handleNext = (itemId) => {
    let body = {
      queueMachine: machineId,
      queueItem: itemId,
      counter: counterId,
    };
    if (!queueProcess.nextLoading) {
    dispatch(nextCallQueueProcess(body))
      .then(async (data) => {
        if (data && data !== undefined) {
          var callText = handleVoiceCall(data.queuePrefixNumber);
          setQueue(data.queuePrefixNumber);
        }
        let payload = {
          skipped: queueProcessSkipped,
          process: allQueueProcess,
          text: callText
        }
    
        await dispatch(getAllSkippedCall(machineId, '', '', ''))
          .then((res) => (payload.skipped = res))
          .catch((err) => console.log(err));
        await dispatch(getAllQueueProcess(machineId, '', '', ''))
          .then((res) => (payload.process = res))
          .catch((err) => console.log(err));
    
      socket.emit('updateAdmissionQueueDisplay' ,{ room, payload })
      })
      .catch((err) => console.log(err));
    }
  };

  const handleRecall = () => {
    const callText = handleVoiceCall(queue);
    sendMessage("re-call", callText);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Header />
      <div className="large-container">
        <div className="large-inner-container">
          <Row className="m-0 mb-4">
            <Col>
              <span className="fw-bold fs-20">{machine?.name}</span>
            </Col>
          </Row>

          <Row className="m-0 mb-4">
            <Col>
              <div onClick={handleBack} className="d-flex">
                <HiArrowSmLeft className="fs-30 cl-green cursor-pointer" />
                <div className="fw-bold fs-18 cursor-pointer mt-01 ml-04">Kembali</div>
              </div>
            </Col>
          </Row>

          <Row className="m-0 mb-4">
            <Col>
              <div className="desk-card">
                <span className="fw-bold fs-40 cl-green">
                  {counter?.name}
                </span>
              </div>
            </Col>
          </Row>

          <Row className="m-0 mb-4">
            <Col>
              <div className="desk-card-v2">
                <div className="desk-card-v2-title">
                  <span className="fw-semi-bold fs-25 cl-white">
                    Nomor Antrian
                  </span>
                </div>

                <div className="desk-card-v2-body">
                  <span className="fw-bold fs-40 cl-green">
                    {queue}
                  </span>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="m-0 mb-4">
            <Col>
              <Button
                title="Panggil"
                onClick={() => handleRecall()}
              />
            </Col>
            <Col>
              {machine?.queueItems?.length > 0 ? (
                machine?.queueItems?.length > 1 ? (
                  <Dropdown>
                    <Dropdown.Toggle className="dropdown-control">
                      Selanjutnya
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu-control">
                      {machine?.queueItems.map(
                        (data, index) => (
                          <Dropdown.Item
                            className="dropdown-item-control"
                            key={index}
                            onClick={() => handleNext(data._id)}
                          >
                            {data.name}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Button
                    title="Selanjutnya"
                    onClick={() =>
                      handleNext(machine?.queueItems[0]._id)
                    }
                  />
                )
              ) : null}
            </Col>
          </Row>
        </div>
      <CopyRightWords/>
      </div>
      {renderModal()}
    </>
  );
};