import { HiArrowSmLeft } from "react-icons/hi"


export default function BackButton ({
    handleBack,
    styleButton
}){
    return (
    <div className="cursor-pointer" onClick={handleBack}>
    <div style={{display:'flex', alignItems:'center', height:'100px', width: 'fit-content', height:'fit-content', background:'white', paddingX:'10px',
        borderRadius:'30px' ,padding: '5px 25px 5px 25px', border: 'solid 1px #00BD84'
    }} >
    <HiArrowSmLeft className={`fs-${styleButton?.fsArrow ? styleButton.fsArrow : '30'} cl-green`} />
    <div className={`fw-${styleButton.fwWord? styleButton.fwWord : "bold"} fs-${styleButton?.fsWord ? styleButton.fsWord : '18'} mt-01 ml-04`} >{styleButton.word ? styleButton.word : 'Kembali'}</div>
    </div>    
    </div>
    )
}