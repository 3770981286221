import authReducers from "./auth.reducers";
import queueMachineReducers from "./queueMachine.reducers";
import counterReducers from "./counter.reducers";
import queueProcessReducers from "./queueProcess.reducers";
import { combineReducers } from "redux";
import policlinicConfigReducers from "./policlinicConfig.reducers";
import bedReducers from './bed.reducers'
import doctorReducers from "./doctor.reducers";

const rootReducer = combineReducers({
  auth: authReducers,
  queueMachine: queueMachineReducers,
  counter: counterReducers,
  queueProcess: queueProcessReducers,
  policlinicConfig: policlinicConfigReducers,
  bed: bedReducers,
  doctorList : doctorReducers
});

export default rootReducer;
