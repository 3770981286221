import { useEffect, useState } from "react";
import { Col, Row, Image } from "react-bootstrap";

export default function Topbar(props) {
  const [time, setTime] = useState(new Date());
  const hospitalLogo = localStorage.getItem("logo");
  const logoSecondary = localStorage.getItem("logoSecondary")

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);

    return function cleanup() {
      clearInterval(timerID);
    };
  }, [time]);

  function tick() {
    setTime(new Date());
  }

  return (
    <Row className="top d-flex justify-content-between">
      <Col className="d-flex align-items-center">
        <Image src={ hospitalLogo ? hospitalLogo : "/img/logo/logo-login-hospita.svg"} height={props.height ? props.height : "60vw"} />
        <Image src={ logoSecondary? logoSecondary : "/img/logo/logo-login-hospita.svg"} height={props.height ? props.height : "60vw"} style={{marginLeft:'20px'}} />
      </Col>
      <Col className="d-flex align-items-center justify-content-end">
        <Row className="justify-content-end">
          <div className="fw-bolder fs-3 text-end">
            {time
              .toLocaleTimeString("id-ID", { hour12: false })
              .replace(/AM|PM/, "")}
          </div>
          <div className="fs-3 fw-bolder text-success text-end">
            {time.toLocaleDateString("id-ID", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </div>
        </Row>
      </Col>
    </Row>
  );
}
