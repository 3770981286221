import axios from "../helpers/axios";
import { ERROR_RESPONSE, authConstants } from "./constants";

export const login = (body) => {
  return async (dispatch) => {
    dispatch({ type: authConstants.LOGIN_REQUEST });

    const res = await axios.post("/api/auth/login", body)
    .catch((err) => {
      return dispatch({
        type: authConstants.LOGIN_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE
      })
    })
    if (res.status === 200) {
      const { accessToken, refreshToken, configs, logo, logoSecondary, hospitalName } = res.data;

      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("username", body.username);
      localStorage.setItem("configs", configs)
      localStorage.setItem("logo", logo)
      localStorage.setItem("logoSecondary", logoSecondary)
      localStorage.setItem("hospitalName", hospitalName)

      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};
