import HeaderDashboard from "../../../components/HeaderDashboard";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { Col, Row, Image } from "react-bootstrap";
import { useEffect, useState } from "react";
import "./index.css";
import InputSelect from "../../../components/InputSelect";
import Modal from "../../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { getAllDoctorPoli, getPoliclinicScreen, updatePoliclinicScreen, createPoliclinicScreen, deletePoliclinicScreen } from "../../../actions/policlinicConfig.actions";
import { useNavigate, useParams } from "react-router-dom";
import { getQueueMachine } from "../../../actions";
import { io } from "socket.io-client";
import { socketBase, socketPath } from "../../../urlConfig";
import PopupConfirmation from "../../../components/PopupConfirmation";
import Swal from "sweetalert2";
import { isAntrol } from "../../../helpers/kioskConfigs";
import { HiArrowSmLeft } from "react-icons/hi";
import { ERROR_RESPONSE } from "../../../actions/constants";
import CopyRightWords from "../../../components/CopyrightWords";

const socket = io(socketBase, {path: socketPath}).connect();

export default function AdminDisplayPoliclinicConfig() {
  const { machineId } = useParams();

  // Room State
  const [room, setRoom] = useState();
  // Doctor Select Options State
  const [doctorPoliOptions, setDoctorPoliOptions] = useState();
  // Doctors State
  const [doctorPoli, setDoctorPoli] = useState([]);

  // Policlinic Screens State
  const [policlinicQueueMachine, setPoliclinicQueueMachine] = useState({});

  const { policlinicScreen, loading, error: policlinicConfigError } = useSelector((state) => state.policlinicConfig);

  // Update Data State
  const [updateData, setUpdateData] = useState({
    policlinicScreenName: "",
    doctors: doctorPoli || [],
  });
  // Create Data State
  const [createData, setCreateData] = useState({
    policlinicScreenName: "",
    doctors: [],
  })
  // Empty Input Field Error State
  const [errorScreenNameUpdate, setErrorScreenNameUpdate] = useState(false)
  const [errorDoctorsUpdate, setErrorDoctorsUpdate] = useState(false)
  const [errorScreenNameCreate, setErrorScreenNameCreate] = useState(false)
  const [errorDoctorsCreate, setErrorDoctorsCreate] = useState(false)
  // Modal State
  const [modal, setModal] = useState(false);
  // Popup Confirmation State
  const [popupCreate, setPopupCreate] = useState(false);
  const [popupUpdate, setPopupUpdate] = useState(false);
  const [popupDelete, setPopupDelete] = useState(false);
  const [popupCancel, setPopupCancel] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  }

  // Reset input field validators
  const handleResetValidators = () => {
    setErrorScreenNameUpdate(false)
    setErrorDoctorsUpdate(false)
    setErrorScreenNameCreate(false)
    setErrorDoctorsCreate(false)
  }

  const handleMapDoctorPoli = () => {
    const mapDoctorPoli = policlinicScreen?.item?.map((doctor) => ({
      value: doctor.uuid,
      label: doctor.name,
      poli: doctor.label,
      schedule: doctor.schedule 
    }));
    setDoctorPoli(mapDoctorPoli)
  };

  useEffect(() => {
    dispatch(getQueueMachine(machineId)).then((res) => {
      setPoliclinicQueueMachine(res)
      if (Object.keys(res?.queueItems?.[0]).length > 0) {
      // Select first poli screen upon entering page
      dispatch(getPoliclinicScreen(res?.queueItems?.[0]?._id)).then((res)=> {
        const mapDoctorPoli = res?.item?.map((doctor) => ({
          value: doctor.uuid,
          label: doctor.name,
          poli: doctor.label,
          schedule: doctor.schedule 
        }));
        setDoctorPoli(mapDoctorPoli)
        setUpdateData({ policlinicScreenName: res?.name, doctors: mapDoctorPoli})
      })
      }
    })

    dispatch(getAllDoctorPoli()).then((res) => {
      const mapDoctorPoliOptions = res?.response?.map((doctor) => ({
        value: doctor.uuid,
        label: doctor.namaDokter,
        poli: doctor.namaPoli,
        schedule: `${doctor?.start?.slice(0,5)} : ${doctor?.end?.slice(0,5)}` 
      }));
      setDoctorPoliOptions(mapDoctorPoliOptions)
    })

    // room for policlinic queue display on the client side
    setRoom(machineId);
  }, [machineId]);

  useEffect(() => {
    if(policlinicConfigError) {
      Swal.fire({
        icon: "error",
        text: ERROR_RESPONSE.errorMessage,
      })
    }
  }, [policlinicConfigError])

  const handleCancel = () => {
    setUpdateData({
      policlinicScreenName: policlinicScreen?.name,
      doctors: doctorPoli || [],
    })
    setCreateData({
      policlinicScreenName: "",
      doctors: [],
    })
    handleResetValidators()
    handleMapDoctorPoli()
  };

  const handleUpdate = () => {
    const mapDoctors = updateData?.doctors?.map((doctor) => ({
      uuid: doctor.value,
      name: doctor.label,
      label: doctor.poli,
      schedule: doctor.schedule
    }))
    const body = {
      name: updateData.policlinicScreenName,
      item: mapDoctors,
      service: 'poli',
    }
    const id = policlinicScreen?._id;
    dispatch(updatePoliclinicScreen(id, body)).then((res) => {
      if(!res) {
        Swal.fire({
          icon: "error",
          text: "Gagal ubah layar tunggu poli klinik"
        })
        handleCancel()
      } else {
      const mapDoctorPoli = res?.item?.map((doctor) => ({
        value: doctor.uuid,
        label: doctor.name,
        poli: doctor.label,
        schedule: doctor.schedule
      }));
      setDoctorPoli(mapDoctorPoli)
      setUpdateData({
        ...updateData,
        doctors: mapDoctorPoli || [],
      })
      handleResetValidators()
      dispatch(getQueueMachine(machineId)).then((res) => {
        setPoliclinicQueueMachine(res)
        //emits an event to update the policlinic queue display on the client side
        socket.emit('updatePoliclinicConfig', { room });
      })
      Swal.fire({
        icon: "success",
        text: "Sukses ubah layar tunggu poli klinik",
        timer: 3000
      })
    }
    })
  }

  const handleCreate = () => {
    const mapDoctors = createData?.doctors?.map((doctor) => ({
      uuid: doctor.value,
      name: doctor.label,
      label: doctor.poli,
      schedule: doctor.schedule 
    }))
    const body = {
      name: createData.policlinicScreenName,
      item: mapDoctors,
      service: 'poli'
    }
    dispatch(createPoliclinicScreen(body)).then((res) => {
      if(!res) {
        Swal.fire({
          icon: "error",
          text: "Gagal tambah layar tunggu poli klinik"
        })
      } else {
      setModal(false);
      setCreateData({
        policlinicScreenName: "",
        doctors: [],
      });
      handleResetValidators()
      dispatch(getQueueMachine(machineId)).then((res) => {
        setPoliclinicQueueMachine(res)
        //set back to first poli screen on active
        dispatch(getPoliclinicScreen(res?.queueItems?.[0]?._id)).then((res) => {
          const mapDoctorPoli = res?.item?.map((doctor) => ({
            value: doctor.uuid,
            label: doctor.name,
            poli: doctor.label,
            schedule: `${doctor?.start?.slice(0,5)} : ${doctor?.end?.slice(0,5)}`
          }));
          setDoctorPoli(mapDoctorPoli)
          setUpdateData({ policlinicScreenName: res?.name, doctors: mapDoctorPoli })
        })
        //emits an event to update the policlinic queue display on the client side
        socket.emit('updatePoliclinicConfig', { room });
      })
      Swal.fire({
        icon: "success",
        text: "Sukses tambah layar tunggu poli klinik",
        timer: 3000
      })
    }
    })
  }

  const handleDelete = () => {
    const id = policlinicScreen?._id;
    dispatch(deletePoliclinicScreen(id)).then((res) => {
      if(!res) {
        Swal.fire({
          icon: "error",
          text: "Gagal hapus layar tunggu poli klinik"
        })
      } else {
      setUpdateData({
        policlinicScreenName: "",
        doctors: [],
      })
      handleResetValidators()
      dispatch(getQueueMachine(machineId)).then((res) => {
        setPoliclinicQueueMachine(res)
        if (Object.keys(res?.queueItems?.[0]).length > 0) {
        //set back to first poli screen on active
        dispatch(getPoliclinicScreen(res?.queueItems?.[0]?._id)).then((res)=> {
          const mapDoctorPoli = res?.item?.map((doctor) => ({
            value: doctor.uuid,
            label: doctor.name,
            poli: doctor.label,
            schedule: `${doctor?.start?.slice(0,5)} : ${doctor?.end?.slice(0,5)}`
          }));
          setDoctorPoli(mapDoctorPoli)
          setUpdateData({ policlinicScreenName: res?.name, doctors: mapDoctorPoli})
        })
        }
        //emits an event to update the policlinic queue display on the client side
        socket.emit('updatePoliclinicConfig', { room });
      })
      Swal.fire({
        icon: "success",
        text: "Sukses hapus layar tunggu poli klinik",
        timer: 3000
      })
    }
    })
  }

  // handle check input field and pop up confirmation for update
  const handleCheckUpdateField = (data) => {
    if(!data.policlinicScreenName.length > 0) {
      setErrorScreenNameUpdate(true)
    }
    if(!data.doctors.length > 0) {
      setErrorDoctorsUpdate(true)
    }
    if(data.policlinicScreenName.length > 0) {
      setErrorScreenNameUpdate(false)
    }
    if(data.doctors.length > 0) {
      setErrorDoctorsUpdate(false)
    }
    if (data.policlinicScreenName.length > 0 && data.doctors.length > 0) {
      setPopupUpdate(true)
    }
    return;
  }

  // handle check input field and pop up confirmation for create
  const handleCheckCreateField = (data) => {
    if(!data.policlinicScreenName.length > 0) {
      setErrorScreenNameCreate(true)
    }
    if(!data.doctors.length > 0) {
      setErrorDoctorsCreate(true)
    }
    if(data.policlinicScreenName.length > 0) {
      setErrorScreenNameCreate(false)
    }
    if(data.doctors.length > 0) {
      setErrorDoctorsCreate(false)
    }
    if (data.policlinicScreenName.length > 0 && data.doctors.length > 0) {
      setPopupCreate(true)
    } 
    return;
  }

  // multi-select style
  const Styles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', border: 0, boxShadow: 'none' }),
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        backgroundColor: isDisabled,
        color: isDisabled,
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles }),
  };

  // handle update data doctor poli options for dispatch
  const updateDataSelectedOptions = (selectedOptions) => {
    setUpdateData({
      ...updateData,
      doctors: selectedOptions
    })
  }

  // handle create data doctor poli options for dispatch
  const createDataSelectedOptions = (selectedOptions) => {
    setCreateData({
      ...createData,
      doctors: selectedOptions
    })
  }

  const renderPoliclinicScreenList = () => {
    return (
      <div className="policlinic-screen-container">
        {/* Show screen list if there is at least 1 screen */}
        {policlinicQueueMachine?.queueItems != null && policlinicQueueMachine?.queueItems?.length > 0 && Object.keys(policlinicQueueMachine?.queueItems?.[0])?.length > 0 ? (
          policlinicQueueMachine?.queueItems?.map((screen, index) => (
            <div className={`policlinic-screen overflow-ellipsis ${screen?._id === policlinicScreen?._id ? "policlinic-screen-active" : "policlinic-screen-inactive"}`} 
            onClick={() => {
              if (!loading) {
              dispatch(getPoliclinicScreen(screen._id)).then((res) => {
              const mapDoctorPoli = res?.item?.map((doctor) => ({
                value: doctor.uuid,
                label: doctor.name,
                poli: doctor.label,
                schedule: `${doctor?.start?.slice(0,5)} : ${doctor?.end?.slice(0,5)}`
              }));
              setDoctorPoli(mapDoctorPoli)
              setUpdateData({ policlinicScreenName: res?.name, doctors: mapDoctorPoli })
              setErrorDoctorsUpdate(false)
              setErrorScreenNameUpdate(false)
              handleResetValidators()
              })
              }
            }}
            >
              <span className="fw-bold fs-16">{screen.name}</span>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    )
  };

  const renderPoliclinicScreenDetails = () => {
    return (
      <>
        <div className="header-content" style={{ paddingRight: "10px" }}>
          <span className="fw-bold fs-20">Detail Layar Tunggu Poli Klinik</span>
          <Button
            title="Hapus"
            className="button-delete"
            onClick={() => {
              if (!loading) {
                setPopupDelete(true)
              }
            }}
          />
        </div>
        <div className="policlinic-screen-container-detail">
                <Col className="mb-3">
                  <span className="fw-semi-bold">Nama Layar</span>
                  <Input
                    type="text"
                    placeholder={"Nama Layar"}
                    onChange={(e) => setUpdateData({ ...updateData, policlinicScreenName: e.target.value })}
                    value={updateData.policlinicScreenName}
                  />
                  {errorScreenNameUpdate &&
                    <span className="error-message">Nama layar harus diisi!</span>
                  }
                </Col>
                <Col className="mb-5">
                  <span className="fw-semi-bold">Dokter</span>
                  <InputSelect
                    isMulti={true}
                    isSearch={true}
                    className={"input-select-control"}
                    isClearable={true}
                    isSearchable={true}
                    styles={Styles}
                    placeholder={"Pilih Dokter"}
                    defaultValue={doctorPoli}
                    options={doctorPoliOptions}
                    onChange={updateDataSelectedOptions}
                  />
                  {errorDoctorsUpdate &&
                  <span className="error-message">Dokter harus diisi!</span>
                  }
                </Col>
                <Col className="d-flex justify-content-end">
                  <Button 
                  title="Batal"
                  className="button-cancel"
                  onClick={() => {
                    if (!loading) {
                    setPopupCancel(true)
                    }
                  }}
                  />
                  <div style={{ paddingRight: "8px" }}></div>
                  <Button 
                  title="Simpan" 
                  className="button-save"
                  onClick={() => {
                    if (!loading) {
                    handleCheckUpdateField(updateData);
                    }
                  }}
                  />
                </Col>
        </div>
      </>
    )
  };

  // modal tambah layar tunggu
  const renderModal = () => {
    return (
      <Modal
        size="lg"
        show={modal}
        handleClose={() => {
          setModal(false)
          setErrorScreenNameCreate(false)
          setErrorDoctorsCreate(false)
          handleCancel()
        }}
        title="Tambah Layar Tunggu Poli Klinik"
      >
        <Row className="m-0">
          <Col className="mb-3">
            <span className="fw-semi-bold">Nama Layar</span>
            <Input
              type="text"
              placeholder="Masukkan Nama Layar"
              onChange={(e) => setCreateData({ ...createData, policlinicScreenName: e.target.value })}
            />
            {errorScreenNameCreate &&
              <span className="error-message">Nama layar harus diisi!</span>
            }
          </Col>
        </Row>
        <Row className="m-0">
          <Col className="mb-3">
            <span className="fw-semi-bold">Dokter</span>
            <InputSelect
              isMulti={true}
              isSearch={true}
              className={"input-select-control"}
              isClearable={true}
              isSearchable={true}
              styles={Styles}
              placeholder={"Pilih Dokter"}
              options={doctorPoliOptions}
              onChange={createDataSelectedOptions}
            />
            {errorDoctorsCreate &&
              <span className="error-message">Dokter harus diisi!</span>
            }
          </Col>
        </Row>

        <Row className="m-0 mb-2 mt-4">
          <Col className="mt-4">
            <Button
              title="Simpan"
              onClick={() => {
                if (!loading) {
                handleCheckCreateField(createData);
                }
               }}
            />
          </Col>
        </Row>

        <Row className="m-0">
            <Col>
            <Button
              title="Batal"
              onClick={() => {
                if (!loading) {
                setModal(false)
                setErrorScreenNameCreate(false)
                setErrorDoctorsCreate(false)
                handleCancel()
                }
              }}
              className="button-cancel-modal"
            />
            </Col>
          </Row>
      </Modal>
    )
  };

  const handleShowModal = async () => {
    setModal(true);
  };

  // Pop up confirmation create
  const renderPopupConfirmCreate = () => {
    return (
      <PopupConfirmation
        show={popupCreate}
        message="Simpan perubahan layar tunggu poli klinik ini?"
        handleClose={() => {
          if (!loading) {
          setPopupCreate(false)
          }
        }}
        handleSave={() => {
          if (!loading) {
          handleCreate()
          setPopupCreate(false)
          }
        }}
      />
    )
  };

  // Pop up confirmation update
  const renderPopupConfirmUpdate = () => {
    return (
      <PopupConfirmation
        show={popupUpdate}
        message="Simpan perubahan layar tunggu poli klinik ini?"
        handleClose={() => {
          if (!loading) {
          setPopupUpdate(false)
          }
        }}
        handleSave={() => {
          if (!loading) {
          handleUpdate()
          setPopupUpdate(false)
          }
        }}
      />
    )
  };

  // Pop up confirmation delete
  const renderPopupConfirmDelete = () => {
    return (
      <PopupConfirmation
        show={popupDelete}
        message="Anda yakin ingin menghapus layar tunggu poli klinik ini?"
        handleClose={() => {
          if (!loading) {
          setPopupDelete(false)
          }
        }}
        handleSave={() => {
          if (!loading) {
          handleDelete()
          setPopupDelete(false)
          }
          }
        }
      />
    )
  }

  // Pop up confirmation cancel
  const renderPopupConfirmCancel = () => {
    return (
      <PopupConfirmation
        message={<>Batalkan perubahan informasi layar tunggu poli klinik ini? <br /> (Perubahan tidak akan disimpan)</>}
        show={popupCancel}
        handleClose={() => {
          if (!loading) {
          setPopupCancel(false)
          }
        }}
        handleSave={() => {
          if (!loading) {
          handleCancel()
          setPopupCancel(false)
          }
        }}
      />
    )
  }

  return isAntrol ? (
    <div style={{overflow: 'hidden'}}>
      <HeaderDashboard />
      <div style={{height:'75vh'}} className="large-container">
        <div style={{ height: '90%'}} className="large-inner-container">
          <Row style={{ overflow: 'hidden', width: '100%', height: '100%'}}>
            <div style={{width: '100%', display: 'flex'}} className="overflow-medium">
            <Col xs={3} style={{ position: "relative", height: '750px' }} className="policlinic-screen-list-col">
              <div onClick={handleBack} className="d-flex mb-4">
                <HiArrowSmLeft className="fs-30 cl-green cursor-pointer" />
                <div className="fw-bold fs-18 cursor-pointer mt-01 ml-04">Kembali</div>
              </div>
              <div className="partition"></div>
              <div className="header-content">
                <span className="fw-bold fs-20">Layar Tunggu Poli Klinik</span>
                <button className="button-create">
                  <Image 
                  src="/img/icon/icon-create-button.svg" 
                  height={"40px"}
                  onClick={() => {
                    if (!loading) {
                    handleShowModal()
                    }
                  }}
                   />
                </button>
              </div>
              <div style={{overflow: "hidden", height: '100%', position: 'relative'}}>
              <div className="policlinic-screen-list">
              {renderPoliclinicScreenList()}
              </div>
              </div>
            </Col>
            <Col xs={9} style={{paddingLeft: "25px", paddingTop: "53px"}} className="policlinic-screen-detail-col">
              {/* Show screen details if there is at least 1 screen */}
              {policlinicQueueMachine?.queueItems?.length > 0 && Object.keys(policlinicQueueMachine.queueItems?.[0])?.length > 0 && (
                renderPoliclinicScreenDetails()
              )
              }
            </Col>
            </div>
          </Row>
        </div>
        <CopyRightWords/>
      </div>
      {renderModal()}
      {renderPopupConfirmCreate()}
      {renderPopupConfirmUpdate()}
      {renderPopupConfirmDelete()}
      {renderPopupConfirmCancel()}
    </div>
  ) : null
}