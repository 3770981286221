import { Row } from "react-bootstrap";
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import "./index.css";
import Button from "../../../../components/Button";
import Pagination from "../../../../components/Pagination";
import BackButton from "../../../../components/BackButton";

const itemsPerPage = 12;


const PatientDisplayDoctor = ({handleBack, handleSelectDoctor, doctor, poliName}) => {
    const navigate = useNavigate()

    // const [currentPage, setCurrentPage] = useState(1);

    // const totalPages = Math.ceil(clinics.length / itemsPerPage);
    // const currentClinics = clinics.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const [paginationProps, setPaginationProps] = useState({
        page: 1,
        limit: itemsPerPage,
        totalData: doctor.length,
    });

    const handlePageChange = (page, limit) => {
        setPaginationProps((prev) => ({ ...prev, page, limit }));
    };

    const currentDoctors = doctor.slice(
        (paginationProps.page - 1) * paginationProps.limit,
        paginationProps.page * paginationProps.limit
    );

    return (
        <div
        style={{ height:'82vh', display:'flex', flexDirection:'column', justifyContent:'space-between'}}
        >
                    <div className="clinic-selection-screen">
                        <h2 style={{fontWeight:'bold'}}>Silakan Pilih Dokter Spesialis {poliName} untuk Konsultasi</h2>
                        <div className="clinic-grid">
                            {currentDoctors.map((doctor) => (
                                <Button
                                    key={doctor.doctorId}
                                    titleReact={true}
                                    title={()=>{
                                        return(
                                            <Row className="m-0 mb-4 clinic-button-container">
                                            <div className="button-patient-style">
                                              <div className="doctor-name-button">{doctor.namaDokter}</div>
                                              <div className="doctor-practice-schedule">Jadwal praktek: {doctor.start.slice(0,5)} - {doctor.end.slice(0,5)}</div>
                                            </div>
                                            </Row>
                                          )
                                    }
                                    }
                                    onClick={() => handleSelectDoctor(doctor)}
                                    className="clinic-button"
                                />
                            ))}
                        </div>
                    </div>
                
                <div className="pagination-controls">
                                <div className="d-flex" style={{gap:'40px'}}>
                                <BackButton styleButton={{fsArrow:40, fwWord: 'normal', fsWord: 25, word : "Kembali" }} handleBack={handleBack} />
                                <div className="cursor-pointer" onClick={()=> navigate('/home')}>
                                     <div style={{display:'flex', alignItems:'center', height:'100px', width: 'fit-content', height:'fit-content', background:'white', paddingX:'10px',
                                              borderRadius:'30px' ,padding: '5px 25px 5px 25px', border: 'solid 1px #00BD84'
                                                 }} >
                                <div className={`fw-normal fs-25 mt-01 ml-04`} >ke Halaman Utama</div>
                                </div>    
                                </div>
                                </div>
                            {
                                paginationProps?.totalData && <Pagination
                                    currData={currentDoctors}
                                    props={paginationProps}
                                    setProps={setPaginationProps}
                                    onChange={handlePageChange}
                                />
                            }
                        </div>
        </div>
    );
}


export default PatientDisplayDoctor;