import axiosInstance from "../helpers/axios";
import { doctorConstants, ERROR_RESPONSE } from "./constants";


export const getAllDoctorAndPoli = () => {
    return async (dispatch) => {
      dispatch({ type: doctorConstants.GET_DOCTOR_LIST_REQUEST });
  
      const res = await axiosInstance.get(`/api/outbound/get-doctor-poli/today`).catch((err) => {
        return dispatch({
          type: doctorConstants.GET_DOCTOR_LIST_FAILURE,
          payload: err.response?.data || ERROR_RESPONSE,
        });
      });
  
      if (res.status === 200) {
        dispatch({
          type: doctorConstants.GET_DOCTOR_LIST_SUCCESS,
          payload: res.data.response,
        });
  
        return res.data;
      }
    };
  };