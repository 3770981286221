import axiosInstance from "../helpers/axios";
import { ERROR_RESPONSE, maxLimit, queueProcessConstants } from "./constants";

export const getAllQueueProcess = (id, category, poliId) => {
  return async (dispatch) => {
    dispatch({ type: queueProcessConstants.GET_ALL_QUEUE_PROCESS_REQUEST });

    const res = await axiosInstance.get(`/api/counter-last-call/poli/${id}/${poliId? poliId : undefined}${category ? `?category=${category}` : ''}`).catch((err) => {
      return dispatch({
        type: queueProcessConstants.GET_ALL_QUEUE_PROCESS_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE,
      });
    });

    if (res.status === 200) {
      dispatch({
        type: queueProcessConstants.GET_ALL_QUEUE_PROCESS_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const getDashboardPayer = (id, counterId) => {
  return async (dispatch) => {
    dispatch({ type: queueProcessConstants.GET_DASHBOARD_REQUEST });

    const res = await axiosInstance.get(`/api/queue-process/dashboard/${id}/${counterId}`).catch((err) => {
      return dispatch({
        type: queueProcessConstants.GET_DASHBOARD_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE,
      });
    });

    if (res.status === 200) {
      dispatch({
        type: queueProcessConstants.GET_DASHBOARD_SUCCESS,
        payload: Array.isArray(res.data)? res.data.length === 0 ? null : res.data : null,
      });

      return res.data;
    }
  };
};

export const getQueueProcess = (machineId, counterId) => {
  return async (dispatch) => {
    dispatch({ type: queueProcessConstants.GET_QUEUE_PROCESS_REQUEST });

    const res = await axiosInstance
      .get(`/api/counter-last-call/${machineId}/${counterId}`)
      .catch((err) => {
        return dispatch({
          type: queueProcessConstants.GET_QUEUE_PROCESS_FAILURE,
          payload: err.response?.data || ERROR_RESPONSE,
        });
      });

    if (res.status === 200) {
      dispatch({
        type: queueProcessConstants.GET_QUEUE_PROCESS_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const getQueueProcessPharmacy = (machineId) => {
  return async (dispatch) => {
    dispatch({ type: queueProcessConstants.GET_QUEUE_PROCESS_REQUEST });

    const res = await axiosInstance
      .get("/api/queue-process/pharmacy", { params: { machineId } })
      .catch((err) => {
        return dispatch({
          type: queueProcessConstants.GET_QUEUE_PROCESS_FAILURE,
          payload: err.response?.data || ERROR_RESPONSE,
        });
      });

      

    if (res.status=== 200) {
     
      dispatch({
        type: queueProcessConstants.GET_QUEUE_PROCESS_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const nextCallQueueProcess = (body) => {
  return async (dispatch) => {
    dispatch({ type: queueProcessConstants.NEXT_CALL_QUEUE_PROCESS_REQUEST });

    const res = await axiosInstance
      .post("/api/queue-process/next-call", body)
      .catch((err) => {
        return dispatch({
          type: queueProcessConstants.NEXT_CALL_QUEUE_PROCESS_FAILURE,
          payload: err.response?.data || ERROR_RESPONSE,
        });
      });

    if (res.status === 200) {
      dispatch({
        type: queueProcessConstants.NEXT_CALL_QUEUE_PROCESS_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const createQueueProcess = (body) => {
  return async (dispatch) => {
    dispatch({ type: queueProcessConstants.CREATE_QUEUE_PROCESS_REQUEST });

    const res = await axiosInstance.post(`/api/queue-process/${!body.isPayer? 'non-payer' : ''}`, !body.isPayer ? {} : body).catch((err) => {
      return dispatch({
        type: queueProcessConstants.CREATE_QUEUE_PROCESS_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE,
      });
    });

    if (res.status === 200) {
      dispatch({
        type: queueProcessConstants.CREATE_QUEUE_PROCESS_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
    throw new Error();
  };
};

export const createQueueProcessPoli = (body) => {
  return async (dispatch) => {
    dispatch({ type: queueProcessConstants.CREATE_QUEUE_PROCESS_REQUEST });

    const res = await axiosInstance.post('/api/queue-process/poli' , body).catch((err) => {
      return dispatch({
        type: queueProcessConstants.CREATE_QUEUE_PROCESS_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE,
      });
    });

    if (res.status === 200) {
      dispatch({
        type: queueProcessConstants.CREATE_QUEUE_PROCESS_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
    throw new Error();
  };
};

export const  getAllSkippedCall = (machineId, limitValue, category, poliId) => {
  return async (dispatch) => {
    dispatch({ type: queueProcessConstants.GET_ALL_SKIPPED_QUEUE_PROCESS_REQUEST });
    const limit = limitValue === null ? null : limitValue ? limitValue : maxLimit.tvDisplayMissed;
    const res = await axiosInstance.get(`/api/counter-last-call/skip/${machineId}/${limit}/${poliId? poliId : undefined}${category ? `?category=${category}` : ''}`)
    .catch((err) => {
      return dispatch({
        type: queueProcessConstants.GET_ALL_SKIPPED_QUEUE_PROCESS_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE,
      });
    });
    if (res.status === 200) {
      dispatch({
        type: queueProcessConstants.GET_ALL_SKIPPED_QUEUE_PROCESS_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  }
}

export const updateSkippedCall = (body) => {
  return async (dispatch) => {
    dispatch({ type: queueProcessConstants.UPDATE_SKIPPED_QUEUE_PROCESS_REQUEST });
    const res = await axiosInstance.put("/api/queue-process/skip-queue/update", body)
      .catch((err) => {
        return dispatch({
          type: queueProcessConstants.UPDATE_SKIPPED_QUEUE_PROCESS_FAILURE,
          payload: err.response?.data || ERROR_RESPONSE,
        });
      });
    if (res.status === 200) {
      dispatch({
        type: queueProcessConstants.UPDATE_SKIPPED_QUEUE_PROCESS_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  }
}