import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllCounter, getAllQueueMachine } from "../../actions";
import Button from "../../components/Button";
import Header from "../../components/Header";
import Modal from "../../components/Modal";
import Select from "../../components/Select";
import "./index.css";
import { allQueueMachine } from "../../actions/constants";
import { isAntrol } from "../../helpers/kioskConfigs";
import { renderModalWarning } from "../../components/RenderModal";
import CopyRightWords from "../../components/CopyrightWords";

const Home = () => {
  const [counterId, setCounterId] = useState("");
  const [detailData, setDetailData] = useState(null);
  const [path, setPath] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalWarning, setModalWarning]= useState(false)
  const [warningMessage, SetWarningMessage] = useState()
  const mapMenu = [
    {name: 'Layar Anjungan', value : 'pasien', label : 'Ambil Antrian', image: '/img/icon/icon-home-patient.svg', navigate: (dataId)=> navigate(`/patient-display/${dataId}`)},
    {name: 'Layar TV', value : 'tv', label : '', image: '/img/icon/icon-home-tv.svg', navigate: (dataId, slug)=>  handleNavigate(dataId, slug) },
    {name: 'Layar Loket', value : 'loket', label : '', image: '/img/icon/icon-home-counter.svg', navigate: (counter, dataId)=> handleShowModal(counter, `/admin-display/display/${dataId}`)},
    {name: 'Konfigurasi', value : 'config', label : '', image: '/img/icon/icon-home-config.svg', navigate: (dataId, slug)=> handleNavigateLoket(dataId,slug)},
  ]

  const queueMachine = useSelector((state) => state.queueMachine);
  const counter = useSelector((state) => state.counter);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const emptyModal = ()=>{
    setModalWarning(false)
    SetWarningMessage()
  }

  useEffect(() => {
    dispatch(getAllQueueMachine()).catch((err) => {
      console.log(err);
    });

    dispatch(getAllCounter()).catch((err) => {
      console.log(err);
    });
  }, []);

  const renderModal = () => {
    return (
      detailData && (
        <Modal
          size="md"
          show={modal}
          handleClose={() => setModal(false)}
          title="Pilih Loket"
        >
          <Row className="m-0 mb-5">
            <Col>
              <Select
                value={counterId}
                onChange={(e) => setCounterId(e.target.value)}
                defaultOption
                defaultOptionTitle="Pilih Loket"
                data={detailData}
              />
            </Col>
          </Row>

          <Row className="m-0 mb-2">
            <Col>
              <Button
                title="Masuk"
                onClick={() =>{
                  if(counterId){
                    navigate(`${path}/${counterId}`)
                  }else{
                    setModalWarning(true)
                    SetWarningMessage('Silakan pilih Loket')
                  }
                }}
              />
            </Col>
          </Row>

          <Row className="m-0">
            <Col>
              <Button title="Batal" onClick={() => setModal(false)} />
            </Col>
          </Row>
        </Modal>
      )
    );
  };

  const handleShowModal = async (data, path) => {
    let newData = [];

    await data.map((data) => {
      newData.push({
        value: data._id,
        title: data.name,
      });
    });

    setDetailData(newData);
    setPath(path);
    setModal(true);
  };

  const handleNavigate =(machineId, slug) => {
   if(isAntrol){
    switch (slug) {
      case allQueueMachine.PHARMACY_SLUG:
        navigate(`/queue-display/pharmacy/${machineId}`);
        break;
      case allQueueMachine.ADMISSION_SLUG:
        navigate(`/queue-display/admission/${machineId}`);
        break;
      case allQueueMachine.POLICLINIC_SLUG:
        navigate(`/queue-display/policlinic/${machineId}`);
        break;
      case allQueueMachine.BED_SLUG:
      navigate(`/queue-display/bed-config/${machineId}`);
      break
      default:
        break;
    }
   } else{
    navigate(`/queue-display/${machineId}`);
   }
  }

  const handleNavigateLoket =(machineId, slug) =>{
    switch (slug) {
      case allQueueMachine.BED_SLUG:
        navigate(`/admin-display/bed-config/${machineId}`);
        break;
      case allQueueMachine.ADMISSION_SLUG:
        navigate(`/admin-display/loket-config/${machineId}`);
        break;
      case allQueueMachine.POLICLINIC_SLUG:
        navigate(`/admin-display/policlinic-config/${machineId}`);
        break;
      default:
        break;
    }
  }

  const mappedPage = (input) => {
    return <Col md={3}>
    <Row className="m-0 mb-3">
       <Col>
       <span className="fw-bold fs-20">{input.name}</span>
       </Col>
    </Row>
    <Row className="m-0 mb-2">
       { queueMachine.allQueueMachine?.length && queueMachine.allQueueMachine.map((data, index) => {
          if(data.layar?.[input.value] == true){
              return( 
               <Col
               className="mb-2 d-flex flex-column text-center"
               xs={6}
               sm={4}
               key={index}
             >
               <div
                 className="mb-1 home-card cursor-pointer"
                 onClick={() =>{
                   switch (input.value) {
                     case 'pasien':
                       input.navigate(data._id)
                       break;
                       case 'tv':
                       input.navigate(data._id, data.slug)
                       break;
                       case 'loket':
                       input.navigate(counter.counter, data._id)
                       break;
                       case 'config':
                       input.navigate(data._id, data.slug)
                       break;
                   
                     default:
                       break;
                   }
                 }}
               >
                 <Image
                   src={input.image}
                   height={"50px"}
                 />
               </div>

               <div>
                  {
                    input.value == 'config' ? 
                    data.slug == allQueueMachine.BED_SLUG ?  <span className="fw-bold">Tampilan Layar {data.name}</span> :
                    data.slug == allQueueMachine.ADMISSION_SLUG ?  <span className="fw-bold">Loket {data.name}</span> :
                    <span className="fw-bold">Layar {data.name}</span> :
                    <span className="fw-bold">{input.label} {input.value != 'pasien'? data.name : ""}</span>
                  }
               </div>
             </Col>
         )}})}
     </Row>
   </Col>
  }

  return (
    <>
      <Header />
      <div className="large-container">
        <div className="large-inner-container">
          <Row>
           {
            mapMenu.map((dataMap)=>mappedPage(dataMap))
           }
          </Row>
          
        </div>
      <CopyRightWords/>
      </div>
      {renderModal()}
      {renderModalWarning({modal: modalWarning, modalMessage:warningMessage, emptyModal})}
    </>
  );
};

export default Home;
