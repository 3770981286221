import { Col, Row } from "react-bootstrap";
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import "./index.css";
import Topbar from "../../../../components/Topbar";
import Button from "../../../../components/Button";
import Pagination from "../../../../components/Pagination";
import BackButton from "../../../../components/BackButton";

const itemsPerPage = 12;


const PatientDisplayPoliclinic = ({handleBack , handleSelectClinic , poli}) => {

    // const [currentPage, setCurrentPage] = useState(1);

    // const totalPages = Math.ceil(clinics.length / itemsPerPage);
    // const currentClinics = clinics.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const [paginationProps, setPaginationProps] = useState({
        page: 1,
        limit: itemsPerPage,
        totalData: poli.length,
    });

    const handlePageChange = (page, limit) => {
        setPaginationProps((prev) => ({ ...prev, page, limit }));
    };

    const currentClinics = poli.slice(
        (paginationProps.page - 1) * paginationProps.limit,
        paginationProps.page * paginationProps.limit
    );

    return (
        <div
        style={{ height:'82vh', display:'flex', flexDirection:'column', justifyContent:'space-between'}}
        >
                    <div className="clinic-selection-screen">
                        <h2  style={{fontWeight:'bold'}}>Silakan Pilih Poliklinik Spesialis yang Ingin Anda Tuju</h2>
                        <div className="clinic-grid">
                            {currentClinics.map((clinic) => (
                                <Button
                                    key={clinic.poliId}
                                    title={clinic.namaPoli}
                                    onClick={() => handleSelectClinic(clinic.poliId)}
                                    className="clinic-button"
                                />
                            ))}
                        </div>
                    </div>
                
                <div className="pagination-controls">
                                <BackButton styleButton={{fsArrow:40, fwWord: 'normal', fsWord: 25, word : "Kembali" }} handleBack={handleBack} />
                            {
                                paginationProps?.totalData && <Pagination
                                    currData={currentClinics}
                                    props={paginationProps}
                                    setProps={setPaginationProps}
                                    onChange={handlePageChange}
                                />
                            }
                        </div>
        </div>
    );
}

export default PatientDisplayPoliclinic;