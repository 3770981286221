import { retrieveLocalStorageItem } from "./localStorage";


const kioskRole = retrieveLocalStorageItem("configs");

let isAntrol;
let isMultiScreen;
let isPayer;
let isDoubleQueue;

if(kioskRole) {
  isAntrol = !!kioskRole?.isAntrol;
  isMultiScreen = !!kioskRole?.isMultiScreen;
  isPayer = !!kioskRole?.isPayer;
  isDoubleQueue= !!kioskRole?.isDoubleQueue;
}

export { isAntrol, isMultiScreen, isPayer, isDoubleQueue }