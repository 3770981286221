import { Col, Row, Carousel, CarouselItem } from "react-bootstrap";
import { useEffect, useState } from "react";
import "./index.css";
import { useParams } from "react-router";
import { getQueueProcessPharmacy } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import Topbar from "../../../components/Topbar";
import { io } from "socket.io-client";
import { socketBase, socketPath } from "../../../urlConfig";
import { queueProcessConstants } from "../../../actions/constants";

const socket = io(socketBase, {path: socketPath}).connect();

export default function QueuePharmacyDisplay() {
  const { queueProcess } = useSelector((state) => state.queueProcess);

  // Text to Speech
  const synth = window?.speechSynthesis;
  const voice = window?.speechSynthesis?.getVoices();
  const indonesianVoice = voice.find((voice) => voice.lang.includes('id-ID'));
  const utterance = new SpeechSynthesisUtterance();
  // set to indonesian voice
  utterance.voice = indonesianVoice;
  utterance.pitch = 1;
  utterance.rate = 1;
  utterance.lang = 'id-ID'

  const statusPharmacy = [
    { name: 'Belum Diproses', value: "pending" },
    { name: "Sedang Diproses", value: "doing" },
    { name: "Siap Diambil", value: "done" },
  ];

  const { machineId } = useParams();
  // Room State
  const [room, setRoom] = useState(null);

  //time for real live clock
  const dispatch = useDispatch();

  useEffect(() => {
    setRoom(machineId);
  }, [machineId]);

  useEffect(() => {
    socket.emit("join_room", room);
  }, [room]);

  useEffect(() => {
    dispatch(getQueueProcessPharmacy(machineId)).catch((err) => {
      console.log(err);
    });
  }, [dispatch, machineId]);

  useEffect(()=>{
    socket.on("update_queue_pharmacy", (data) => {
      const findIndex = queueProcess.findIndex((value)=> value.id === data.responsePharmacy.id)
      const handleSocket = [...queueProcess]
      handleSocket[findIndex] = data.responsePharmacy
      dispatch({
        type: queueProcessConstants.GET_QUEUE_PROCESS_SUCCESS,
        payload: handleSocket,
      });
      if (data?.queue){
        utterance.text = `Nomor antrian ${data.queue}, silakan menuju loket farmasi`;
        synth.speak(utterance)
        synth.cancel()
      }
    })
    socket.on("pharmacyNextCall", (data) => {
      if(queueProcess) {
        for (const item of queueProcess) {
          const findObject = item.done.data.findIndex(
            (obj) => obj.queuePrefixNumber === data.queueNumber
          )
          if(findObject !== -1 && data.message){
            utterance.text = data.message;
            synth.speak(utterance)
            synth.cancel()
          }
        }
      }
    })
    return () => {
      socket.off("update_queue_pharmacy");
      socket.off("pharmacyNextCall");
  }
  },[queueProcess,socket])


  const mappedQueue = () => {
    return queueProcess?.map((data) => {
      return (
        <>
          <div className="title-table">{data.name}</div>
          <Row>
            {statusPharmacy?.map((value, index) => {
              const carouselItems = [];
              for (
                let i = 0;
                i <= Math.floor(data[value.value].total / 20);
                i++
              ) {
                carouselItems.push(
                  data[value.value].data.slice(i * 20, (i + 1) * 20)
                );
              }

              return (
                <Col key={index} className="px-4">
                  {data[value.value].data?.length !== 0 ? (
                    <Carousel
                      indicators={true}
                      nextIcon={null}
                      prevIcon={null}
                      variant="dark"
                      style={{
                        padding: 0,
                        margin: 0,
                        backgroundColor: "white",
                        borderRadius: "6px",
                      }}
                      interval={10000}
                    >
                      {carouselItems?.map((carouselItem) => {
                        return (
                          <CarouselItem>
                            <Row
                              md={4}
                              style={{
                                borderRadius: "6px",
                                height: "20vh",
                                margin: 0,
                                paddingBottom: 40,
                              }}
                            >
                              {carouselItem?.map((dataInside) => {
                                return (
                                  <Col
                                    key={dataInside.queuePrefixNumber}
                                    className="mapped-table"
                                  >
                                    {dataInside.queuePrefixNumber}
                                  </Col>
                                );
                              })}
                            </Row>
                          </CarouselItem>
                        );
                      })}
                    </Carousel>
                  ) : (
                    <Row
                      style={{
                        textAlign: "center",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: "white",
                        fontWeight: 700,
                        fontSize: "1.2vw",
                        borderRadius: "6px",
                        height: "20vh",
                      }}
                    >
                      Belum ada Antrian
                    </Row>
                  )}
                </Col>
              );
            })}
          </Row>
        </>
      );
    });
  };

  return (
    <div className="queue-bg">
      <Col>
        <div className="topbar-display">
        <Topbar />
        </div>
        <Row className="bot">
          <div className="antrian text-center fw-bold">ANTRIAN FARMASI</div>
          <div className="table px-5 border-black">
            <Row
              style={{
                height: "content",
                gap: "20px",
                marginLeft: 0,
                marginRight: 0,
              }}
            >
              {statusPharmacy?.map((data) => (
                <Col className="words">{data.name}</Col>
              ))}
            </Row>
            {queueProcess?.length === 0 ? (
              <>
                <div className="title-table">Belum Ada Antrian</div>
                <div
                  style={{
                    textAlign: "center",
                    alignContent: "center",
                    backgroundColor: "white",
                    fontWeight: 700,
                    fontSize: "1.2vw",
                    borderRadius: "6px",
                    height: "20vh",
                  }}
                >
                  Belum Ada Antrian
                </div>
              </>
            ) : (
              mappedQueue()
            )}
          </div>
        </Row>
      </Col>
    </div>
  );
}
