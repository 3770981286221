import { doctorConstants } from "../actions/constants";

const initState = {
  doctorList: [],
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case doctorConstants.GET_DOCTOR_LIST_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case doctorConstants.GET_DOCTOR_LIST_SUCCESS:
      state = {
        ...state,
        doctorList: action.payload,
        loading: false,
      };
      break;
    case doctorConstants.GET_DOCTOR_LIST_FAILURE:
      state = {
        ...state,
        doctorList: [],
        error: action.payload,
        loading: false,
      };
      break
    default: 
      break;
  }
  return state;
};
